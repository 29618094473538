const colors = {
  white: '#fff',
  textLight: '#fff',
  textDark: '#1e1a1a',
  background: '#cf9c63',
  backgroundLight: '#ede1d3',
  backgroundDark: '#83623c',
  actionPrimary: '#6c9092',
  actionPrimaryLight: '#9eb6b8',
  actionSecondary: '#f26c4f',
};

const theme = {
  colors,
};

export default theme;
