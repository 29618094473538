import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../utils/theme.js';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export { Layout };
export default Layout;
